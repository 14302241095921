import React from "react";
import {
  Navbar,
  Typography,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Collapse,
} from "@material-tailwind/react";
import {
  Square3Stack3DIcon,
  ChevronDownIcon,
  Bars2Icon,
} from "@heroicons/react/24/solid";

 
// nav list menu
const navListMenuItems = [
  {
    title: "Sillas generales",
    description:
      "Encuentra ofertas en todas nuestras categorias.",
    id: 0
  },
  {
    title: "Sillas de diseño",
    description:
      "Encuentra ofertas en todas nuestras sillas con diseños unicos.",
    id: 1
  },
  {
    title: "Sillas de escritorio",
    description:
      "Encuentra ofertas en nuestras sillas de escritorio.",
    id:2
  },
  {
    title: "Sillas de barra",
    description:
      "Encuentra oferta en nuestras sillas de barra.",
    id:3
  },
  {
    title: "Sillas Gamers",
    description:
      "Encuentra oferta en nuestras sillas Gamers.",
    id:4
  },
];

 
function NavListMenu({cty, setOpen}) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const setCategory = (id) => {
    cty(id);
    setOpen(false);
  }
 
  const renderItems = navListMenuItems.map(({ title, description, id }) => (
    <a key={title}>
      <MenuItem onClick={() => setCategory(id)}>
        <Typography variant="h6" color="blue-gray" className="mb-1">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));
 
  return (
    <React.Fragment>
      <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem className="hidden items-center gap-2 font-medium text-blue-gray-900 lg:flex lg:rounded-full">
              <Square3Stack3DIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
              Categoria{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-[36rem] grid-cols-4 overflow-visible lg:grid">
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 font-medium text-blue-gray-900 lg:hidden">
        <Square3Stack3DIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
        Categoria{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
    </React.Fragment>
  );
}

 
function NavList({cty, setOpen}) {
  return (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      <NavListMenu cty={cty} setOpen={setOpen}/>
    </ul>
  );
}

 
export function ComplexNavbar({cty}) {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
 
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

 
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false),
    );
  }, []);
 
  return (
    <Navbar className="mx-auto max-w-screen-xl p-2 lg:rounded-full lg:pl-6">
      <div className="relative mx-auto flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="#"
          className="mr-4 ml-2 cursor-pointer py-1.5 font-medium"
        >
          <b>CajuHogar</b>
        </Typography>
        <div className="hidden lg:block">
          <NavList cty={cty} setOpen={setIsNavOpen}/>
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
      </div>
      <Collapse open={isNavOpen} className="overflow-scroll">
        <NavList cty={cty} setOpen={setIsNavOpen}/>
      </Collapse>
    </Navbar>
  );
}