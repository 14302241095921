import { Navigate, Route, Routes } from "react-router-dom";
import { ProducPage } from "../productPage";
import { ProductDetail } from "../../product-detail/productDetail";

export const RouterPages = () => {
     
    return <>
    <Routes>
        <Route path="/products" element={<ProducPage/>} />
        <Route path="/detail/:productId" element={<ProductDetail/>}/>
        <Route path="/*" element= {<Navigate to="/products"/>} />
    </Routes>
    </>
}