import React, { useEffect, useState } from 'react';
import { ContentCard } from "../../components/contentCard";
import { Spinner, Button, Tooltip } from "@material-tailwind/react";
import { Paginator } from '../../components/paginator';
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/solid";

  const fetchProducts = async (page) => {
    const response = await fetch(`https://api.cajuhogar.com:8080/product?page=${page}`);
    const data = await response.json();
    return data
};

 const fetchProductByCategory = async (id, page) => {
  const response = await fetch(`https://api.cajuhogar.com:8080/product/category?id=${id}&page=${page}`);
    const data = await response.json();
    return data
 }

 const wppClick = (nombre) => {
  const phoneNumber = '573138819090';
  const message = `Hola, estoy interesado en información de CajuHogar!`;

  // Crear la URL para WhatsApp
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  // Abrir la URL en una nueva pestaña
  window.open(url, '_blank');
};
  
  export function Product( {idCategory} ) {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [change, setChange] = useState(0);
    const [page, setPage] = useState(0);

    useEffect(() => {
      setPage(0);
      setChange(change + 1);
    }, [idCategory]);

    useEffect(() => {
      setLoading(true);
      if(idCategory === 0 || idCategory === undefined){
      fetchProducts(page).then( data => {
        setData(data)
        setLoading(false);
      });
    }else{
      fetchProductByCategory(idCategory, page).then (
        data => {
          setLoading(true);
          setData(data)
          setLoading(false);
        }
      );
    }
  }, [change,page]);

  if(loading){
    return <>
    <div className="flex items-center justify-center h-screen">
      <Spinner className="h-16 w-16 text-gray-900/50" />
    </div>
    </>
  }
   
    return (
      <section className="container mx-auto">
        <div className="mt-10 grid grid-cols-1 gap-10 lg:grid-cols-3">
          {
          data.products.map((details) => (
            <ContentCard key={details.id} detail={details}/>
          ))
          }
        </div>
        <br/>
        <div className="fixed bottom-4 right-4">
          <Button
            color="green"
            className="rounded-full p-4 shadow-lg"
            onClick={wppClick}
          >
            <Tooltip content="Enviar mensaje Wpp" placement="top">
            <ChatBubbleOvalLeftIcon className="text-white w-6 h-6" />
            </Tooltip>
          </Button>
        </div>
        <div className="flex items-center justify-center">
          <Paginator pages={data.totalPages} setPages={setPage} pageNumber={page}/>
        </div>
      </section>
    );
  }