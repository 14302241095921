import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
} from "@material-tailwind/react";
import { useParams, useNavigate } from 'react-router-dom';
import { Carousel, IconButton } from "@material-tailwind/react";
import { Spinner } from "@material-tailwind/react";

const fetchProducts = async (id) => {
  const response = await fetch(`https://api.cajuhogar.com:8080/gallery?id=${id}`);
  const data = await response.json();
  return data;
};


const wppClick = (nombre) => {
  const phoneNumber = '573145275089';
  const message = `Hola, estoy interesado en la silla ${nombre}!`;

  // Crear la URL para WhatsApp
  const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  // Abrir la URL en una nueva pestaña
  window.open(url, '_blank');
};

export function ProductDetail() {

    const { productId } = useParams();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
      fetchProducts(productId).then(data =>{
        setData(data);
        setLoading(false)
      })
  }, []);

    const navigate = useNavigate();

    const goToHome = () => {
        navigate('/products');
    }

    if(loading){
      return <>
      <div className="flex items-center justify-center h-screen">
        <Spinner className="h-16 w-16 text-gray-900/50" />
      </div>
      </>
    }

  return (
    <section className="py-16 px-8">
      <div className="mx-auto container grid place-items-center grid-cols-1 md:grid-cols-2">
      <Carousel className="rounded-xl"
      prevArrow={({ handlePrev }) => (
        <IconButton
          variant="text"
          color="black"
          size="lg"
          onClick={handlePrev}
          className="!absolute top-2/4 left-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
            />
          </svg>
        </IconButton>
      )}
      nextArrow={({ handleNext }) => (
        <IconButton
          variant="text"
          color="black"
          size="lg"
          onClick={handleNext}
          className="!absolute top-2/4 !right-4 -translate-y-2/4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
            />
          </svg>
        </IconButton>
      )}
      >
      {
      data.galleries.map((gallery) =>(
          <img key={gallery.id}
          src={gallery.url}
          alt={gallery.id}
          className="h-full w-full object-cover"
        />
        ))}
      </Carousel>
      <div>
  <Typography className="mb-4 text-2xl sm:text-3xl md:text-3xl">
    <b>{data.title}</b>
  </Typography>
  <Typography className="text-lg sm:text-xl md:text-xl"><b>${data.price}</b></Typography>
  <Typography className="!mt-4 text-base font-normal leading-[27px] !text-gray-500 sm:text-lg">
    {data.description}
  </Typography>
  <br />
  <div className="mb-4 flex flex-col sm:flex-row gap-3 w-full sm:w-3/4 md:w-1/2 lg:w-1/3">
    <Button color="gray" className="w-full sm:w-52" onClick={() => wppClick(data.title)}>
      Comprar
    </Button>
    <Button color="gray" className="w-full sm:w-52" onClick={goToHome}>
      Regresar
    </Button>
  </div>
</div>
      </div>
    </section>
  );
}