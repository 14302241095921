import './App.css';
import { Footer } from './components/footer';
import { BrowserRouter } from 'react-router-dom';
import { RouterPages } from './pages/product/router/router';

function App() {
  return (
    <>
    <BrowserRouter>
    <RouterPages/>
    <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
