
import { useState } from 'react';
import { ComplexNavbar } from "../../components/navbar";
import { Product } from './product';

export function ProducPage(){

    const [category, setCategory] = useState(0);

    const handleCategoryChange = (newValue) => {
        setCategory(newValue);
    }
    return(
        <>
        <ComplexNavbar cty={handleCategoryChange}/>
        <Product  idCategory={category}/>
        </>
    );
}